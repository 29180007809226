@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BiroScriptPlus';
  src: url('/fonts/BiroScriptPlus.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@layer base {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;

    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

html {
  font-size: calc(0.95rem + 1vw);
}
/* body{
  font-family: __Poppins_da1309, __Poppins_Fallback_da1309;
} */
.tilt-text {
  perspective: 1000px;
}
.handwritten-text{
  font-family: BiroScriptPlus;
  /* color:pink; */
}
.featured-cards-container {
  perspective: 100px;
}
.card-opacity {
  pointer-events: none;
}

.feature-carousel::before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 1.5rem;
  z-index: -1;
}

@layer components {
  .rounded-card {
    @apply w-full min-h-[17.87rem] 
      rounded-[2rem] overflow-hidden flex flex-row p-[.5rem];
  }
  .blue-pin {
    @apply w-[1.39em] h-[1.39em] absolute rounded-full 
     bg-gradient-pin;
  }
  .mask-container {
    @apply w-[100%] h-screen overflow-hidden absolute top-0;
  }
  .slide-cards {
    @apply relative;
  }
  .car-nav-long {
    @apply pointer-events-none	w-[1.88rem] h-[.25rem] rounded-[10rem] bg-white-100;
  }
  .car-nav-short {
    @apply pointer-events-none	w-[1.38rem] h-[.25rem] rounded-[10rem] bg-white-100 mb-[.3em];
  }

  .roomies-col {
    @apply h-[12.3rem] justify-between content-center flex flex-col w-[9.9rem] text-center;
  }
  .interest-tube {
    @apply relative shadow-tube w-[9.13rem] h-[1.71rem] rounded-full;
  }
  .int-percentage {
    @apply absolute w-[75%] h-full bg-green-200 border-2 border-green-550 rounded-full;
  }
  .per-number {
    @apply absolute top-[50%] text-sm25 translate-y-[-50%] right-[.7rem];
  }
  .interest-name {
    @apply absolute top-[50%] capitalize text-[.63rem] translate-y-[-50%] left-[.9rem];
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: #000;
  background-color: #fff;
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    ) */
  /* rgb(var(--background-start-rgb)); */
}
